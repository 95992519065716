module.exports = function (Segment, Utils) {
    'use strict';
    function WaveformSegments(peaks) {
        this._peaks = peaks;
        this._segments = [];
        this._segmentsById = {};
        this._segmentIdCounter = 0;
    }
    WaveformSegments.prototype._getNextSegmentId = function () {
        return 'peaks.segment.' + this._segmentIdCounter++;
    };
    function g() {
        return Math.floor(Math.random() * 255);
    }
    WaveformSegments.prototype.getSegmentColor = function () {
        if (this._peaks.options.randomizeSegmentColor) {
            return 'rgba(' + g() + ', ' + g() + ', ' + g() + ', 1)';
        } else {
            return this._peaks.options.segmentColor;
        }
    };
    WaveformSegments.prototype._createSegment = function (options) {
        if (!Utils.isObject(options)) {
            throw new TypeError('peaks.segments.add(): expected a Segment object parameter');
        }
        if (!Utils.isValidTime(options.startTime)) {
            throw new TypeError('peaks.segments.add(): startTime should be a valid number');
        }
        if (!Utils.isValidTime(options.endTime)) {
            throw new TypeError('peaks.segments.add(): endTime should be a valid number');
        }
        if (options.startTime < 0) {
            throw new RangeError('peaks.segments.add(): startTime should not be negative');
        }
        if (options.endTime < 0) {
            throw new RangeError('peaks.segments.add(): endTime should not be negative');
        }
        if (options.endTime <= options.startTime) {
            throw new RangeError('peaks.segments.add(): endTime should be greater than startTime');
        }
        var segment = new Segment(Utils.isNullOrUndefined(options.id) ? this._getNextSegmentId() : options.id, options.startTime, options.endTime, options.labelText || '', options.color || this.getSegmentColor(), options.editable || false);
        if (this._segmentsById.hasOwnProperty(segment.id)) {
            throw new Error('peaks.segments.add(): duplicate id');
        }
        this._segments.push(segment);
        this._segmentsById[segment.id] = segment;
        return segment;
    };
    WaveformSegments.prototype.getSegments = function () {
        return this._segments;
    };
    WaveformSegments.prototype.getSegment = function (id) {
        return this._segmentsById[id] || null;
    };
    WaveformSegments.prototype.find = function (startTime, endTime) {
        var segments = [];
        for (var i = 0, length = this._segments.length; i < length; i++) {
            var segment = this._segments[i];
            if (segment.isVisible(startTime, endTime)) {
                segments.push(segment);
            }
        }
        return segments;
    };
    WaveformSegments.prototype.add = function (segmentOrSegments) {
        var segments = Array.isArray(arguments[0]) ? arguments[0] : Array.prototype.slice.call(arguments);
        if (typeof segments[0] === 'number') {
            this._peaks.options.deprecationLogger('peaks.segments.add(): expected a segment object or an array');
            segments = [{
                    startTime: arguments[0],
                    endTime: arguments[1],
                    editable: arguments[2],
                    color: arguments[3],
                    labelText: arguments[4]
                }];
        }
        segments = segments.map(this._createSegment.bind(this));
        this._peaks.emit('segments.add', segments);
    };
    WaveformSegments.prototype._findSegment = function (predicate) {
        var indexes = [];
        for (var i = 0, length = this._segments.length; i < length; i++) {
            if (predicate(this._segments[i])) {
                indexes.push(i);
            }
        }
        return indexes;
    };
    WaveformSegments.prototype._removeIndexes = function (indexes) {
        var removed = [];
        for (var i = 0; i < indexes.length; i++) {
            var index = indexes[i] - removed.length;
            var itemRemoved = this._segments.splice(index, 1)[0];
            delete this._segmentsById[itemRemoved.id];
            removed.push(itemRemoved);
        }
        return removed;
    };
    WaveformSegments.prototype._removeSegments = function (predicate) {
        var indexes = this._findSegment(predicate);
        var removed = this._removeIndexes(indexes);
        this._peaks.emit('segments.remove', removed);
        return removed;
    };
    WaveformSegments.prototype.remove = function (segment) {
        var removed = this._removeSegments(function (s) {
            return s === segment;
        });
        if (removed.length === 0) {
            throw new Error('peaks.segments.remove(): Unable to find the segment: ' + String(segment));
        } else if (removed.length > 1) {
            this._peaks.logger('peaks.segments.remomve(): Found multiple matching segments: ' + String(segment));
            return removed;
        } else {
            return removed[0];
        }
    };
    WaveformSegments.prototype.removeById = function (segmentId) {
        return this._removeSegments(function (segment) {
            return segment.id === segmentId;
        });
    };
    WaveformSegments.prototype.removeByTime = function (startTime, endTime) {
        endTime = typeof endTime === 'number' ? endTime : 0;
        var fnFilter;
        if (endTime > 0) {
            fnFilter = function (segment) {
                return segment.startTime === startTime && segment.endTime === endTime;
            };
        } else {
            fnFilter = function (segment) {
                return segment.startTime === startTime;
            };
        }
        return this._removeSegments(fnFilter);
    };
    WaveformSegments.prototype.removeAll = function () {
        this._segments = [];
        this._peaks.emit('segments.remove_all');
    };
    return WaveformSegments;
}(require('peaks/markers/segment'), require('peaks/waveform/waveform.utils'));