module.exports = function (Konva) {
    'use strict';
    return {
        create: function (currentScale, previousScale, view) {
            var currentTime = view.peaks.player.getCurrentTime();
            var frameData = [];
            var inputIndex;
            var outputIndex;
            var lastFrameOffsetTime;
            var rootData = view.originalWaveformData;
            view.beginZoom();
            var frameCount = previousScale < currentScale ? 15 : 30;
            for (var i = 0; i < frameCount; i++) {
                var frameScale = Math.floor(previousScale + i * (currentScale - previousScale) / frameCount);
                var newWidthSeconds = view.width * frameScale / rootData.adapter.sample_rate;
                if (currentTime >= 0 && currentTime <= newWidthSeconds / 2) {
                    inputIndex = 0;
                    outputIndex = 0;
                } else if (currentTime <= rootData.duration && currentTime >= rootData.duration - newWidthSeconds / 2) {
                    lastFrameOffsetTime = rootData.duration - newWidthSeconds;
                    inputIndex = lastFrameOffsetTime * rootData.adapter.sample_rate / previousScale;
                    outputIndex = lastFrameOffsetTime * rootData.adapter.sample_rate / frameScale;
                } else {
                    var oldPixelIndex = currentTime * rootData.adapter.sample_rate / previousScale;
                    var newPixelIndex = currentTime * rootData.adapter.sample_rate / frameScale;
                    inputIndex = oldPixelIndex - view.width / 2;
                    outputIndex = newPixelIndex - view.width / 2;
                }
                if (inputIndex < 0) {
                    inputIndex = 0;
                }
                var resampled = rootData.resample({
                    scale: frameScale,
                    input_index: Math.floor(inputIndex),
                    output_index: Math.floor(outputIndex),
                    width: view.width
                });
                frameData.push(resampled);
            }
            var animationFrameFunction = this.createAnimationFrameFunction(view, frameData);
            return new Konva.Animation(animationFrameFunction, view);
        },
        createAnimationFrameFunction: function (view, frameData) {
            var index = 0;
            view.intermediateData = null;
            return function (frame) {
                if (index < frameData.length) {
                    view.intermediateData = frameData[index];
                    index++;
                    view.zoomWaveformLayer.draw();
                } else {
                    this.stop();
                    view.intermediateData = null;
                    view.endZoom();
                }
            };
        }
    };
}(require('konva'));