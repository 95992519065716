module.exports = function (WaveformData, webaudioBuilder, WaveformOverview, WaveformZoomView, WaveformSegments, WaveformPoints) {
    'use strict';
    var isXhr2 = 'withCredentials' in new XMLHttpRequest();
    function Waveform(peaks) {
        this.peaks = peaks;
    }
    Waveform.prototype.init = function (ui) {
        this.ui = ui;
        this.onResize = this.onResize.bind(this);
        this.getRemoteData(this.peaks.options);
    };
    Waveform.prototype.getRemoteData = function (options) {
        var self = this;
        var xhr = new XMLHttpRequest();
        var uri = null;
        var requestType = null;
        var builder = null;
        if (options.dataUri) {
            if (typeof options.dataUri === 'string') {
                var dataUri = {};
                dataUri[options.dataUriDefaultFormat || 'json'] = options.dataUri;
                options.dataUri = dataUri;
            }
            if (typeof options.dataUri === 'object') {
                [
                    'ArrayBuffer',
                    'JSON'
                ].some(function (connector) {
                    if (window[connector]) {
                        requestType = connector.toLowerCase();
                        uri = options.dataUri[requestType];
                        return Boolean(uri);
                    }
                });
            }
        }
        if (!options.dataUri && options.audioContext) {
            requestType = 'arraybuffer';
            uri = options.mediaElement.currentSrc || options.mediaElement.src;
            builder = webaudioBuilder;
        }
        if (!uri) {
            throw new Error('Unable to determine a compatible dataUri format for this browser.');
        }
        xhr.open('GET', uri, true);
        if (isXhr2) {
            try {
                xhr.responseType = requestType;
            } catch (e) {
            }
        }
        xhr.onload = function (response) {
            if (this.readyState !== 4) {
                return;
            }
            if (this.status !== 200) {
                self.handleRemoteData(new Error('Unable to fetch remote data. HTTP Status ' + this.status));
                return;
            }
            if (builder) {
                webaudioBuilder(options.audioContext, response.target.response, options.waveformBuilderOptions, self.handleRemoteData.bind(self));
            } else {
                self.handleRemoteData(null, response.target, xhr);
            }
        };
        xhr.onerror = function (err) {
            setTimeout(function () {
                self.peaks.emit('error', new Error('XHR Failed'));
            }, 0);
        };
        xhr.send();
    };
    Waveform.prototype.handleRemoteData = function (err, remoteData, xhr) {
        if (err) {
            this.peaks.emit('error', err);
            return;
        }
        this.originalWaveformData = null;
        try {
            this.originalWaveformData = remoteData instanceof WaveformData ? remoteData : WaveformData.create(remoteData);
            this.waveformOverview = new WaveformOverview(this.originalWaveformData, this.ui.overview, this.peaks);
            this.waveformZoomView = new WaveformZoomView(this.originalWaveformData, this.ui.zoom, this.peaks);
            this.peaks.emit('segments.ready');
            this.peaks.emit('peaks.ready');
        } catch (e) {
            this.peaks.emit('error', e);
            return;
        }
        this._bindEvents();
    };
    Waveform.prototype._bindEvents = function () {
        var self = this;
        self.peaks.on('user_seek', function (time) {
            self.peaks.player.seek(time);
        });
        window.addEventListener('resize', self.onResize);
    };
    Waveform.prototype.destroy = function () {
        if (this.waveformOverview) {
            this.waveformOverview.destroy();
            this.waveformOverview = null;
        }
        if (this.waveformZoomView) {
            this.waveformZoomView.destroy();
            this.waveformZoomView = null;
        }
        window.removeEventListener('resize', this.onResize);
        if (this.resizeTimeoutId) {
            clearTimeout(this.resizeTimeoutId);
            this.resizeTimeoutId = null;
        }
    };
    Waveform.prototype.onResize = function () {
        var self = this;
        self.peaks.emit('window_resize');
        if (self.resizeTimeoutId) {
            clearTimeout(self.resizeTimeoutId);
        }
        self.resizeTimeoutId = setTimeout(function () {
            var width = self.ui.player.clientWidth;
            self.peaks.emit('window_resize_complete', width);
        }, 500);
    };
    return Waveform;
}(require('waveform-data'), require('waveform-data/webaudio'), require('peaks/views/waveform.overview'), require('peaks/views/waveform.zoomview'), require('peaks/markers/waveform.segments'), require('peaks/markers/waveform.points'));