module.exports = function () {
    'use strict';
    function zeroPad(number) {
        return number < 10 ? '0' + number : number;
    }
    return {
        formatTime: function (time, dropHundredths) {
            var result = [];
            var hundredths = Math.floor(time % 1 * 100);
            var seconds = Math.floor(time);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            if (hours > 0) {
                result.push(hours);
            }
            result.push(minutes % 60);
            result.push(seconds % 60);
            for (var i = 0; i < result.length; i++) {
                result[i] = zeroPad(result[i]);
            }
            result = result.join(':');
            if (!dropHundredths) {
                result += '.' + zeroPad(hundredths);
            }
            return result;
        },
        clamp: function (value, min, max) {
            if (value < min) {
                return min;
            } else if (value > max) {
                return max;
            } else {
                return value;
            }
        },
        isNumber: function (value) {
            return typeof value === 'number';
        },
        extend: function (to, from) {
            for (var key in from) {
                if (from.hasOwnProperty(key)) {
                    to[key] = from[key];
                }
            }
            return to;
        },
        isValidTime: function (value) {
            return typeof value === 'number' && Number.isFinite(value);
        },
        isObject: function (value) {
            return value !== null && typeof value === 'object' && !Array.isArray(value);
        },
        isString: function (value) {
            return typeof value === 'string';
        },
        isNullOrUndefined: function (value) {
            return value === undefined || value === null;
        },
        isFunction: function (value) {
            return typeof value === 'function';
        }
    };
}();