module.exports = function () {
    'use strict';
    function TimeController(peaks) {
        this._peaks = peaks;
    }
    TimeController.prototype.setCurrentTime = function (time) {
        this._peaks.options.deprecationLogger('peaks.time.setCurrentTime(): this function is deprecated. Call peaks.player.seek() instead');
        return this._peaks.player.seek(time);
    };
    TimeController.prototype.getCurrentTime = function () {
        this._peaks.options.deprecationLogger('peaks.time.setCurrentTime(): this function is deprecated. Call peaks.player.getCurrentTime() instead');
        return this._peaks.player.getCurrentTime();
    };
    return TimeController;
}();