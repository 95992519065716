module.exports = function (EventEmitter, WaveformPoints, WaveformSegments, Player, TimeController, ZoomController, Waveform, mixins, Utils, KeyboardHandler) {
    'use strict';
    function buildUi(container) {
        return {
            player: container.querySelector('.waveform'),
            zoom: container.querySelector('.zoom-container'),
            overview: container.querySelector('.overview-container')
        };
    }
    function Peaks(container) {
        EventEmitter.call(this, { wildcard: true });
        this.options = {
            zoomLevels: [
                512,
                1024,
                2048,
                4096
            ],
            dataUri: null,
            dataUriDefaultFormat: 'json',
            logger: null,
            deprecationLogger: console.log.bind(console),
            keyboard: false,
            nudgeIncrement: 1,
            inMarkerColor: '#a0a0a0',
            outMarkerColor: '#a0a0a0',
            zoomWaveformColor: 'rgba(0, 225, 128, 1)',
            overviewWaveformColor: 'rgba(0,0,0,0.2)',
            overviewHighlightRectangleColor: 'grey',
            randomizeSegmentColor: true,
            height: 200,
            segmentColor: 'rgba(255, 161, 39, 1)',
            playheadColor: 'rgba(0, 0, 0, 1)',
            playheadTextColor: '#aaa',
            axisGridlineColor: '#ccc',
            axisLabelColor: '#aaa',
            template: [
                '<div class="waveform">',
                '<div class="zoom-container"></div>',
                '<div class="overview-container"></div>',
                '</div>'
            ].join(''),
            pointMarkerColor: '#FF0000',
            pointDblClickHandler: null,
            pointDragEndHandler: null,
            audioContext: null,
            waveformBuilderOptions: {
                scale: 512,
                amplitude_scale: 1
            },
            zoomAdapter: 'static'
        };
        this.container = container;
        this.logger = console.error.bind(console);
    }
    Peaks.init = function init(opts) {
        opts = opts || {};
        opts.deprecationLogger = opts.deprecationLogger || console.log.bind(console);
        if (opts.audioElement) {
            opts.mediaElement = opts.audioElement;
            opts.deprecationLogger('Peaks.init(): the audioElement option is deprecated, please use mediaElement instead');
        }
        if (!opts.mediaElement) {
            throw new Error('Peaks.init(): Missing mediaElement option');
        }
        if (!(opts.mediaElement instanceof HTMLMediaElement)) {
            throw new TypeError('Peaks.init(): The mediaElement option should be an HTMLMediaElement');
        }
        if (!opts.container) {
            throw new Error('Peaks.init(): Missing container option');
        }
        if (opts.container.clientWidth > 0 === false) {
            throw new TypeError('Peaks.init(): Please ensure that the container has a width');
        }
        if (opts.logger && !Utils.isFunction(opts.logger)) {
            throw new TypeError('Peaks.init(): The logger option should be a function');
        }
        if (!opts.dataUri && !(opts.audioContext instanceof AudioContext)) {
            throw new TypeError('Peaks.init(): You must pass an AudioContext to render waveform data or a dataUri');
        }
        if (opts.dataUri && opts.audioContext) {
            throw new Error('Peaks.init(): You must pass in either an AudioContext or dataUri to render waveform data, not both');
        }
        var instance = new Peaks(opts.container);
        Utils.extend(instance.options, opts);
        Utils.extend(instance.options, {
            segmentInMarker: mixins.defaultInMarker(instance.options),
            segmentOutMarker: mixins.defaultOutMarker(instance.options),
            segmentLabelDraw: mixins.defaultSegmentLabelDraw(instance.options),
            pointMarker: mixins.defaultPointMarker(instance.options)
        });
        if (!Array.isArray(instance.options.zoomLevels)) {
            throw new TypeError('Peaks.init(): The zoomLevels option should be an array');
        }
        if (instance.options.zoomLevels.length === 0) {
            throw new Error('Peaks.init(): The zoomLevels array must not be empty');
        }
        if (opts.logger) {
            instance.logger = opts.logger;
        }
        instance.on('error', instance.logger.bind(null));
        if (typeof instance.options.template === 'string') {
            instance.container.innerHTML = instance.options.template;
        } else if (instance.options.template instanceof HTMLElement) {
            instance.container.appendChild(instance.options.template);
        } else {
            throw new TypeError('Peaks.init(): The template option must be a valid HTML string or a DOM object');
        }
        if (instance.options.keyboard) {
            instance.keyboardHandler = new KeyboardHandler(instance);
        }
        instance.player = new Player(instance, instance.options.mediaElement);
        instance.segments = new WaveformSegments(instance);
        instance.points = new WaveformPoints(instance);
        instance.waveform = new Waveform(instance);
        instance.waveform.init(buildUi(instance.container));
        instance.zoom = new ZoomController(instance, instance.options.zoomLevels);
        instance.time = new TimeController(instance);
        instance.on('peaks.ready', function () {
            if (instance.options.segments) {
                if (!Array.isArray(instance.options.segments)) {
                    throw new TypeError('Peaks.init(): options.segments must be an array of segment objects');
                }
                instance.segments.add(instance.options.segments);
            }
            if (instance.options.points) {
                if (!Array.isArray(instance.options.points)) {
                    throw new TypeError('Peaks.init(): options.points must be an array of point objects');
                }
                instance.points.add(instance.options.points);
            }
        });
        return instance;
    };
    Peaks.prototype = Object.create(EventEmitter.prototype);
    Peaks.prototype.destroy = function () {
        this.removeAllListeners();
        this.waveform.destroy();
        this.player.destroy();
    };
    return Peaks;
}(require('EventEmitter'), require('peaks/markers/waveform.points'), require('peaks/markers/waveform.segments'), require('peaks/player/player'), require('peaks/views/waveform.timecontroller'), require('peaks/views/waveform.zoomcontroller'), require('peaks/waveform/waveform.core'), require('peaks/waveform/waveform.mixins'), require('peaks/waveform/waveform.utils'), require('peaks/player/player.keyboard'));