module.exports = function (mixins, Konva) {
    'use strict';
    return {
        createShape: function (segment, view) {
            var shape = new Konva.Shape({
                fill: segment.color,
                strokeWidth: 0,
                opacity: 1,
                sceneFunc: function (context) {
                    var waveformData = view.data;
                    mixins.drawWaveform(context, waveformData, view.frameOffset, view.timeToPixels(segment.startTime), view.timeToPixels(segment.endTime), view.width, view.height);
                    context.fillStrokeShape(shape);
                }
            });
            return shape;
        }
    };
}(require('peaks/waveform/waveform.mixins'), require('konva'));