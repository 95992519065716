module.exports = function (Utils, Konva) {
    'use strict';
    function PlayheadLayer(peaks, stage, view, showTime, playheadPixel) {
        this._peaks = peaks;
        this._stage = stage;
        this._view = view;
        this._playheadPixel = playheadPixel;
        this._playheadLineAnimation = null;
        this._createPlayhead(showTime, peaks.options.playheadColor, peaks.options.playheadTextColor);
        this.zoomLevelChanged();
    }
    PlayheadLayer.prototype.zoomLevelChanged = function () {
        var pixelsPerSecond = this._view.timeToPixels(1);
        var time;
        this._useAnimation = pixelsPerSecond >= 5;
        if (this._useAnimation) {
            if (this._view.isPlaying() && !this._playheadLineAnimation) {
                time = this._peaks.player.getCurrentTime();
                this.playFrom(time);
            }
        } else {
            if (this._playheadLineAnimation) {
                time = this._peaks.player.getCurrentTime();
                this.stop(time);
            }
        }
    };
    PlayheadLayer.prototype._createPlayhead = function (showTime, playheadColor, playheadTextColor) {
        this._playheadLayer = new Konva.Layer();
        this._playheadLine = new Konva.Line({
            points: [
                0.5,
                0,
                0.5,
                this._view.height
            ],
            stroke: playheadColor,
            strokeWidth: 1
        });
        if (showTime) {
            this._playheadText = new Konva.Text({
                x: 2,
                y: 12,
                text: '00:00:00',
                fontSize: 11,
                fontFamily: 'sans-serif',
                fill: playheadTextColor,
                align: 'right'
            });
        }
        this._playheadGroup = new Konva.Group({
            x: 0,
            y: 0
        });
        this._playheadGroup.add(this._playheadLine);
        if (showTime) {
            this._playheadGroup.add(this._playheadText);
        }
        this._playheadLayer.add(this._playheadGroup);
        this._stage.add(this._playheadLayer);
    };
    PlayheadLayer.prototype.syncPlayhead = function (pixelIndex) {
        var isVisible = pixelIndex >= this._view.frameOffset && pixelIndex < this._view.frameOffset + this._view.width;
        this._playheadPixel = pixelIndex;
        if (isVisible) {
            var playheadX = this._playheadPixel - this._view.frameOffset;
            if (!this._playheadVisible) {
                this._playheadVisible = true;
                this._playheadGroup.show();
            }
            this._playheadGroup.setAttr('x', playheadX);
            if (this._playheadText) {
                var text = Utils.formatTime(this._view.pixelsToTime(this._playheadPixel), false);
                this._playheadText.setText(text);
            }
            this._playheadLayer.draw();
        } else {
            if (this._playheadVisible) {
                this._playheadVisible = false;
                this._playheadGroup.hide();
                this._playheadLayer.draw();
            }
        }
    };
    PlayheadLayer.prototype.playFrom = function (startTime) {
        var self = this;
        if (!self._useAnimation) {
            return;
        }
        if (self._playheadLineAnimation) {
            self._playheadLineAnimation.stop();
            self._playheadLineAnimation = null;
        }
        var lastPlayheadPosition = null;
        self._playheadLineAnimation = new Konva.Animation(function (frame) {
            var elapsed = frame.time / 1000;
            var playheadPosition = self._view.timeToPixels(startTime + elapsed);
            if (playheadPosition !== lastPlayheadPosition) {
                self.syncPlayhead(playheadPosition);
            }
        }, self._playheadLayer);
        self._playheadLineAnimation.start();
    };
    PlayheadLayer.prototype.stop = function (time) {
        if (this._playheadLineAnimation) {
            this._playheadLineAnimation.stop();
            this._playheadLineAnimation = null;
        }
        this.syncPlayhead(this._view.timeToPixels(time));
    };
    PlayheadLayer.prototype.getPlayheadOffset = function () {
        return this._playheadPixel - this._view.frameOffset;
    };
    PlayheadLayer.prototype.getPlayheadPixel = function () {
        return this._playheadPixel;
    };
    return PlayheadLayer;
}(require('peaks/waveform/waveform.utils'), require('konva'));