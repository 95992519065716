module.exports = function (Utils, Konva) {
    'use strict';
    function getLabelText(point) {
        if (point.labelText) {
            return point.labelText;
        } else {
            return Utils.formatTime(point.time, false);
        }
    }
    function PointsLayer(peaks, stage, view, allowEditing) {
        this._peaks = peaks;
        this._stage = stage;
        this._view = view;
        this._allowEditing = true;
        this._pointGroups = {};
        this._createLayer();
    }
    PointsLayer.prototype._createLayer = function () {
        var self = this;
        this._layer = new Konva.Layer();
        this._stage.add(this._layer);
        this._peaks.on('points.add', function (points) {
            var frameStartTime = self._view.pixelsToTime(self._view.frameOffset);
            var frameEndTime = self._view.pixelsToTime(self._view.frameOffset + self._view.width);
            points.forEach(function (point) {
                if (point.isVisible(frameStartTime, frameEndTime)) {
                    self._addPointGroup(point);
                }
            });
            self.updatePoints(frameStartTime, frameEndTime);
        });
        this._peaks.on('points.remove', function (points) {
            points.forEach(function (point) {
                self._removePoint(point);
            });
            self._layer.draw();
        });
        this._peaks.on('points.remove_all', function () {
            self._layer.removeChildren();
            self._pointGroups = {};
            self._layer.draw();
        });
        this._peaks.on('points.dragged', function (point) {
            self._updatePoint(point);
            self._layer.draw();
        });
    };
    PointsLayer.prototype._createPointGroup = function (point) {
        var pointGroup = new Konva.Group();
        pointGroup.point = point;
        if (this._allowEditing && point.editable) {
            var PointMarker = this._peaks.options.pointMarker;
            pointGroup.marker = new PointMarker(true, pointGroup, point, this._layer, this._onPointHandleDrag.bind(this), this._peaks.options.pointDblClickHandler, this._peaks.options.pointDragEndHandler);
            pointGroup.add(pointGroup.marker);
        }
        return pointGroup;
    };
    PointsLayer.prototype._addPointGroup = function (point) {
        var pointGroup = this._createPointGroup(point);
        this._pointGroups[point.id] = pointGroup;
        this._layer.add(pointGroup);
        return pointGroup;
    };
    PointsLayer.prototype._onPointHandleDrag = function (pointGroup, point) {
        var markerX = pointGroup.marker.getX();
        if (markerX > 0 && markerX < this._view.width) {
            var offset = this._view.frameOffset + markerX + pointGroup.marker.getWidth();
            point.time = this._view.pixelsToTime(offset);
        }
        this._peaks.emit('points.dragged', point);
    };
    PointsLayer.prototype.updatePoints = function (startTime, endTime) {
        var points = this._peaks.points.find(startTime, endTime);
        var count = points.length;
        points.forEach(this._updatePoint.bind(this));
        count += this._removeInvisiblePoints(startTime, endTime);
        if (count > 0) {
            this._layer.draw();
        }
    };
    PointsLayer.prototype._updatePoint = function (point) {
        var pointGroup = this._pointGroups[point.id];
        if (!pointGroup) {
            pointGroup = this._addPointGroup(point);
        }
        var timestampOffset = this._view.timeToPixels(point.time);
        var startPixel = timestampOffset - this._view.frameOffset;
        if (this._allowEditing && point.editable) {
            if (pointGroup.marker) {
                pointGroup.marker.setX(startPixel - pointGroup.marker.getWidth());
                pointGroup.marker.label.setText(getLabelText(point));
            }
        }
    };
    PointsLayer.prototype._removeInvisiblePoints = function (startTime, endTime) {
        var self = this;
        var count = 0;
        Object.keys(this._pointGroups).forEach(function (pointId) {
            var point = self._pointGroups[pointId].point;
            if (!point.isVisible(startTime, endTime)) {
                self._removePoint(point);
                count++;
            }
        });
        return count;
    };
    PointsLayer.prototype._removePoint = function (point) {
        var pointGroup = this._pointGroups[point.id];
        if (pointGroup) {
            pointGroup.destroyChildren();
            pointGroup.destroy();
            delete this._pointGroups[point.id];
        }
    };
    PointsLayer.prototype.setVisible = function (visible) {
        this._layer.setVisible(visible);
    };
    return PointsLayer;
}(require('peaks/waveform/waveform.utils'), require('konva'));