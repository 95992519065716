module.exports = function (Konva) {
    'use strict';
    function segmentHandleCreator(height, color, inMarker) {
        return function createSegmentHandle(draggable, segmentGroup, segment, layer, onDrag) {
            var handleHeight = 20;
            var handleWidth = handleHeight / 2;
            var handleY = height / 2 - 10.5;
            var handleX = -(handleWidth / 2) + 0.5;
            var group = new Konva.Group({
                draggable: draggable,
                dragBoundFunc: function (pos) {
                    var limit;
                    if (inMarker) {
                        limit = segmentGroup.outMarker.getX() - segmentGroup.outMarker.getWidth();
                        if (pos.x > limit) {
                            pos.x = limit;
                        }
                    } else {
                        limit = segmentGroup.inMarker.getX() + segmentGroup.inMarker.getWidth();
                        if (pos.x < limit) {
                            pos.x = limit;
                        }
                    }
                    return {
                        x: pos.x,
                        y: this.getAbsolutePosition().y
                    };
                }
            });
            group.on('dragmove', function (event) {
                onDrag(segmentGroup, segment);
            });
            var xPosition = inMarker ? -24 : 24;
            var text = new Konva.Text({
                x: xPosition,
                y: height / 2 - 5,
                text: '',
                fontSize: 10,
                fontFamily: 'sans-serif',
                fill: '#000',
                textAlign: 'center'
            });
            text.hide();
            group.label = text;
            var handle = new Konva.Rect({
                x: handleX,
                y: handleY,
                width: handleWidth,
                height: handleHeight,
                fill: color,
                stroke: color,
                strokeWidth: 1
            });
            var line = new Konva.Line({
                x: 0,
                y: 0,
                points: [
                    0.5,
                    0,
                    0.5,
                    height
                ],
                stroke: color,
                strokeWidth: 1
            });
            handle.on('mouseover', function (event) {
                if (inMarker) {
                    text.setX(xPosition - text.getWidth());
                }
                text.show();
                layer.draw();
            });
            handle.on('mouseout', function (event) {
                text.hide();
                layer.draw();
            });
            group.add(text);
            group.add(line);
            group.add(handle);
            return group;
        };
    }
    function pointHandleCreator(height, color) {
        return function createPointHandle(draggable, pointGroup, point, layer, onDrag, onDblClick, onDragEnd) {
            var handleTop = height / 2 - 10.5;
            var handleWidth = 10;
            var handleHeight = 20;
            var handleX = -(handleWidth / 2) + 0.5;
            var handleColor = point.color ? point.color : color;
            var group = new Konva.Group({
                draggable: draggable,
                dragBoundFunc: function (pos) {
                    return {
                        x: pos.x,
                        y: this.getAbsolutePosition().y
                    };
                }
            });
            group.on('dragmove', function (event) {
                onDrag(pointGroup, point);
            });
            if (onDblClick) {
                group.on('dblclick', function (event) {
                    onDblClick(point);
                });
            }
            if (onDragEnd) {
                group.on('dragend', function (event) {
                    onDragEnd(point);
                });
            }
            var xPosition = -handleWidth;
            var text = new Konva.Text({
                x: xPosition,
                y: height / 2 - 5,
                text: '',
                textAlign: 'center',
                fontSize: 10,
                fontFamily: 'sans-serif',
                fill: '#000'
            });
            text.hide();
            group.label = text;
            var handle = new Konva.Rect({
                x: handleX,
                y: handleTop,
                width: handleWidth,
                height: handleHeight,
                fill: handleColor
            });
            var line = new Konva.Line({
                x: 0,
                y: 0,
                points: [
                    0,
                    0,
                    0,
                    height
                ],
                stroke: handleColor,
                strokeWidth: 1
            });
            handle.on('mouseover', function (event) {
                text.show();
                text.setX(xPosition - text.getWidth());
                layer.draw();
            });
            handle.on('mouseout', function (event) {
                text.hide();
                layer.draw();
            });
            group.add(handle);
            group.add(line);
            group.add(text);
            return group;
        };
    }
    function scaleY(amplitude, height) {
        var range = 256;
        var offset = 128;
        return height - (amplitude + offset) * height / range;
    }
    function drawWaveform(context, waveformData, frameOffset, startPixels, endPixels, width, height) {
        if (startPixels < frameOffset) {
            startPixels = frameOffset;
        }
        var limit = frameOffset + width;
        if (endPixels > limit) {
            endPixels = limit;
        }
        var adapter = waveformData.adapter;
        var x, val;
        context.beginPath();
        for (x = startPixels; x < endPixels; x++) {
            val = adapter.at(2 * x);
            context.lineTo(x - frameOffset + 0.5, scaleY(val, height) + 0.5);
        }
        for (x = endPixels - 1; x >= startPixels; x--) {
            val = adapter.at(2 * x + 1);
            context.lineTo(x - frameOffset + 0.5, scaleY(val, height) + 0.5);
        }
        context.closePath();
    }
    return {
        drawWaveform: drawWaveform,
        waveformOverviewMarkerDrawFunction: function (xIndex, viewGroup, view) {
            viewGroup.waveformShape.setPoints([
                xIndex,
                0,
                xIndex,
                view.height
            ]);
        },
        defaultInMarker: function (options) {
            return segmentHandleCreator(options.height, options.inMarkerColor, true);
        },
        defaultOutMarker: function (options) {
            return segmentHandleCreator(options.height, options.outMarkerColor, false);
        },
        defaultPointMarker: function (options) {
            return pointHandleCreator(options.height, options.pointMarkerColor);
        },
        defaultSegmentLabelDraw: function (options) {
            return function (segmentGroup, segment) {
                return new Konva.Text({
                    x: 12,
                    y: 12,
                    text: segment.labelText,
                    textAlign: 'center',
                    fontSize: 12,
                    fontFamily: 'Arial, sans-serif',
                    fill: '#000'
                });
            };
        }
    };
}(require('konva'));