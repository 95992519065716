module.exports = function () {
    'use strict';
    function Point(id, time, labelText, color, editable) {
        this.id = id;
        this.time = time;
        this.labelText = labelText;
        this.color = color;
        this.editable = editable;
    }
    Point.prototype.isVisible = function (startTime, endTime) {
        return this.time >= startTime && this.time < endTime;
    };
    return Point;
}();