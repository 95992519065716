module.exports = function (Point, Utils) {
    'use strict';
    function WaveformPoints(peaks) {
        this._peaks = peaks;
        this._points = [];
        this._pointsById = {};
        this._pointIdCounter = 0;
    }
    WaveformPoints.prototype._getNextPointId = function () {
        return 'peaks.point.' + this._pointIdCounter++;
    };
    WaveformPoints.prototype._createPoint = function (options) {
        if (options.hasOwnProperty('timestamp') || !options.hasOwnProperty('time')) {
            this._peaks.options.deprecationLogger('peaks.points.add(): The \'timestamp\' attribute is deprecated; use \'time\' instead');
            options.time = options.timestamp;
        }
        if (!Utils.isValidTime(options.time)) {
            throw new TypeError('peaks.points.add(): time should be a numeric value');
        }
        if (options.time < 0) {
            throw new TypeError('peaks.points.add(): time should not be negative');
        }
        if (Utils.isNullOrUndefined(options.labelText)) {
            options.labelText = '';
        } else if (!Utils.isString(options.labelText)) {
            throw new TypeError('peaks.points.add(): labelText must be a string');
        }
        var point = new Point(Utils.isNullOrUndefined(options.id) ? this._getNextPointId() : options.id, options.time, options.labelText, options.color, Boolean(options.editable));
        if (this._pointsById.hasOwnProperty(point.id)) {
            throw new Error('peaks.points.add(): duplicate id');
        }
        this._points.push(point);
        this._pointsById[point.id] = point;
        return point;
    };
    WaveformPoints.prototype.getPoints = function () {
        return this._points;
    };
    WaveformPoints.prototype.getPoint = function (id) {
        return this._pointsById[id] || null;
    };
    WaveformPoints.prototype.find = function (startTime, endTime) {
        var points = [];
        for (var i = 0, length = this._points.length; i < length; i++) {
            var point = this._points[i];
            if (point.isVisible(startTime, endTime)) {
                points.push(point);
            }
        }
        return points;
    };
    WaveformPoints.prototype.add = function (pointOrPoints) {
        var points = Array.isArray(arguments[0]) ? arguments[0] : Array.prototype.slice.call(arguments);
        if (typeof points[0] === 'number') {
            this._peaks.options.deprecationLogger('peaks.points.add(): expected a segment object or an array');
            points = [{
                    time: arguments[0],
                    editable: arguments[1],
                    color: arguments[2],
                    labelText: arguments[3]
                }];
        }
        points = points.map(this._createPoint.bind(this));
        this._peaks.emit('points.add', points);
    };
    WaveformPoints.prototype._findPoint = function (predicate) {
        var indexes = [];
        for (var i = 0, length = this._points.length; i < length; i++) {
            if (predicate(this._points[i])) {
                indexes.push(i);
            }
        }
        return indexes;
    };
    WaveformPoints.prototype._removeIndexes = function (indexes) {
        var removed = [];
        for (var i = 0; i < indexes.length; i++) {
            var index = indexes[i] - removed.length;
            var itemRemoved = this._points.splice(index, 1)[0];
            delete this._pointsById[itemRemoved.id];
            removed.push(itemRemoved);
        }
        return removed;
    };
    WaveformPoints.prototype._removePoints = function (predicate) {
        var indexes = this._findPoint(predicate);
        var removed = this._removeIndexes(indexes);
        this._peaks.emit('points.remove', removed);
        return removed;
    };
    WaveformPoints.prototype.remove = function (point) {
        var removed = this._removePoints(function (p) {
            return p === point;
        });
        if (removed.length === 0) {
            throw new Error('peaks.points.remove(): Unable to find the point: ' + String(point));
        } else if (removed.length > 1) {
            this._peaks.logger('peaks.points.remomve(): Found multiple matching points: ' + String(point));
            return removed;
        } else {
            return removed[0];
        }
    };
    WaveformPoints.prototype.removeById = function (pointId) {
        return this._removePoints(function (point) {
            return point.id === pointId;
        });
    };
    WaveformPoints.prototype.removeByTime = function (time) {
        return this._removePoints(function (point) {
            return point.time === time;
        });
    };
    WaveformPoints.prototype.removeAll = function () {
        this._points = [];
        this._peaks.emit('points.remove_all');
    };
    return WaveformPoints;
}(require('peaks/markers/point'), require('peaks/waveform/waveform.utils'));