module.exports = function (Utils) {
    'use strict';
    function Player(peaks, mediaElement) {
        var self = this;
        self._peaks = peaks;
        self._listeners = [];
        self._mediaElement = mediaElement;
        self._duration = self.getDuration();
        if (self._mediaElement.readyState === 4) {
            self._peaks.emit('player_load', self);
        }
        self._addMediaListener('timeupdate', function () {
            self._peaks.emit('player_time_update', self.getCurrentTime());
        });
        self._addMediaListener('play', function () {
            self._peaks.emit('player_play', self.getCurrentTime());
        });
        self._addMediaListener('pause', function () {
            self._peaks.emit('player_pause', self.getCurrentTime());
        });
        self._addMediaListener('seeked', function () {
            self._peaks.emit('player_seek', self.getCurrentTime());
        });
        self._interval = null;
    }
    Player.prototype._addMediaListener = function (type, callback) {
        this._listeners.push({
            type: type,
            callback: callback
        });
        this._mediaElement.addEventListener(type, callback);
    };
    Player.prototype.destroy = function () {
        for (var i = 0; i < this._listeners.length; i++) {
            var listener = this._listeners[i];
            this._mediaElement.removeEventListener(listener.type, listener.callback);
        }
        this.listeners = [];
        if (self._interval !== null) {
            clearTimeout(self._interval);
            self._interval = null;
        }
    };
    Player.prototype.setSource = function (source) {
        this._mediaElement.setAttribute('src', source);
    };
    Player.prototype.getSource = function () {
        return this._mediaElement.src;
    };
    Player.prototype.play = function () {
        this._mediaElement.play();
    };
    Player.prototype.pause = function () {
        this._mediaElement.pause();
    };
    Player.prototype.getCurrentTime = function () {
        return this._mediaElement.currentTime;
    };
    Player.prototype.getDuration = function () {
        return this._mediaElement.duration;
    };
    Player.prototype.seek = function (time) {
        this._mediaElement.currentTime = time;
    };
    Player.prototype.playSegment = function (segment) {
        var self = this;
        if (!segment || !Utils.isValidTime(segment.startTime) || !Utils.isValidTime(segment.endTime)) {
            self._peaks.logger('peaks.player.playSegment(): parameter must be a segment object');
            return;
        }
        clearTimeout(self._interval);
        self._interval = null;
        self.seek(segment.startTime);
        self._mediaElement.play();
        self._interval = setInterval(function () {
            if (self.getCurrentTime() >= segment.endTime || self._mediaElement.paused) {
                clearTimeout(self._interval);
                self._interval = null;
                self._mediaElement.pause();
            }
        }, 30);
    };
    return Player;
}(require('peaks/waveform/waveform.utils'));