module.exports = function () {
    'use strict';
    function Segment(id, startTime, endTime, labelText, color, editable) {
        this.id = id;
        this.startTime = startTime;
        this.endTime = endTime;
        this.labelText = labelText;
        this.color = color;
        this.editable = editable;
    }
    Segment.prototype.isVisible = function (startTime, endTime) {
        return this.startTime < endTime && startTime < this.endTime;
    };
    return Segment;
}();