module.exports = function (Utils, Konva) {
    'use strict';
    function roundUpToNearest(value, multiple) {
        var remainder = value % multiple;
        if (remainder === 0) {
            return value;
        } else {
            return value + multiple - remainder;
        }
    }
    function WaveformAxis(view, layer) {
        var self = this;
        var axisShape = new Konva.Shape({
            fill: 'rgba(38, 255, 161, 1)',
            strokeWidth: 0,
            opacity: 1,
            sceneFunc: function (context) {
                self.drawAxis(context, view);
            }
        });
        layer.add(axisShape);
    }
    WaveformAxis.prototype.getAxisLabelScale = function (view) {
        var baseSecs = 1;
        var steps = [
            1,
            2,
            5,
            10,
            20,
            30
        ];
        var minSpacing = 60;
        var index = 0;
        var secs;
        for (;;) {
            secs = baseSecs * steps[index];
            var pixels = view.timeToPixels(secs);
            if (pixels < minSpacing) {
                if (++index === steps.length) {
                    baseSecs *= 60;
                    index = 0;
                }
            } else {
                break;
            }
        }
        return secs;
    };
    WaveformAxis.prototype.drawAxis = function (context, view) {
        var currentFrameStartTime = view.pixelsToTime(view.frameOffset);
        var markerHeight = 10;
        var axisLabelIntervalSecs = this.getAxisLabelScale(view);
        var firstAxisLabelSecs = roundUpToNearest(currentFrameStartTime, axisLabelIntervalSecs);
        var axisLabelOffsetSecs = firstAxisLabelSecs - currentFrameStartTime;
        var axisLabelOffsetPixels = view.timeToPixels(axisLabelOffsetSecs);
        context.setAttr('strokeStyle', view.options.axisGridlineColor);
        context.setAttr('lineWidth', 1);
        context.setAttr('font', '11px sans-serif');
        context.setAttr('fillStyle', view.options.axisLabelColor);
        context.setAttr('textAlign', 'left');
        context.setAttr('textBaseline', 'bottom');
        var secs = firstAxisLabelSecs;
        var x;
        for (;;) {
            x = axisLabelOffsetPixels + view.timeToPixels(secs - firstAxisLabelSecs);
            if (x >= view.width) {
                break;
            }
            context.beginPath();
            context.moveTo(x + 0.5, 0);
            context.lineTo(x + 0.5, 0 + markerHeight);
            context.moveTo(x + 0.5, view.height);
            context.lineTo(x + 0.5, view.height - markerHeight);
            context.stroke();
            var label = Utils.formatTime(secs, true);
            var labelWidth = context._context.measureText(label).width;
            var labelX = x - labelWidth / 2;
            var labelY = view.height - 1 - markerHeight;
            if (labelX >= 0) {
                context.fillText(label, labelX, labelY);
            }
            secs += axisLabelIntervalSecs;
        }
    };
    return WaveformAxis;
}(require('peaks/waveform/waveform.utils'), require('konva'));