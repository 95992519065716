module.exports = function (WaveformAxis, mixins, Utils, PlayheadLayer, PointsLayer, SegmentsLayer, MouseDragHandler, AnimatedZoomAdapter, StaticZoomAdapter, Konva) {
    'use strict';
    function WaveformZoomView(waveformData, container, peaks) {
        var self = this;
        self.originalWaveformData = waveformData;
        self.container = container;
        self.peaks = peaks;
        self.options = peaks.options;
        self._playing = false;
        self.playheadVisible = false;
        self.data = null;
        self.pixelLength = 0;
        self.intermediateData = null;
        var initialZoomLevel = self.options.zoomLevels[peaks.zoom.getZoom()];
        self.resampleData(initialZoomLevel);
        self.width = container.clientWidth;
        self.height = container.clientHeight || self.options.height;
        self.frameOffset = 0;
        self.stage = new Konva.Stage({
            container: container,
            width: self.width,
            height: self.height
        });
        self.backgroundLayer = new Konva.Layer();
        self.background = new Konva.Rect({
            x: 0,
            y: 0,
            width: self.width,
            height: self.height
        });
        self.backgroundLayer.add(self.background);
        self.stage.add(self.backgroundLayer);
        self.waveformLayer = new Konva.FastLayer();
        self.axis = new WaveformAxis(self, self.waveformLayer);
        self.createWaveform();
        self._segmentsLayer = new SegmentsLayer(peaks, self.stage, self, true);
        self._pointsLayer = new PointsLayer(peaks, self.stage, self, true);
        var playheadPixel = self.timeToPixels(self.options.mediaElement.currentTime);
        self._playheadLayer = new PlayheadLayer(peaks, self.stage, self, true, playheadPixel);
        self.mouseDragHandler = new MouseDragHandler(self.stage, {
            onMouseDown: function (mousePosX) {
                this.initialFrameOffset = self.frameOffset;
                this.mouseDownX = mousePosX;
            },
            onMouseMove: function (mousePosX) {
                var diff = this.mouseDownX - mousePosX;
                var newFrameOffset = Utils.clamp(this.initialFrameOffset + diff, 0, self.pixelLength - self.width);
                if (newFrameOffset !== this.initialFrameOffset) {
                    self.peaks.emit('user_scroll.zoomview', newFrameOffset);
                }
            },
            onMouseUp: function (mousePosX) {
                if (!self.mouseDragHandler.isDragging()) {
                    var mouseDownX = Math.floor(this.mouseDownX);
                    var pixelIndex = self.frameOffset + mouseDownX;
                    var time = self.pixelsToTime(pixelIndex);
                    self.updateWaveform(pixelIndex - mouseDownX);
                    self._playheadLayer.syncPlayhead(pixelIndex);
                    self.peaks.player.seek(time);
                    if (self._playing) {
                        self._playheadLayer.playFrom(time);
                    }
                }
            }
        });
        self.peaks.on('player_time_update', function (time) {
            if (self.mouseDragHandler.isDragging()) {
                return;
            }
            var pixelIndex = self.timeToPixels(time);
            self._playheadLayer.syncPlayhead(pixelIndex);
            if (self._playing) {
                var endThreshold = self.frameOffset + self.width - 100;
                if (pixelIndex >= endThreshold || pixelIndex < self.frameOffset) {
                    self.frameOffset = pixelIndex - 100;
                    if (self.frameOffset < 0) {
                        self.frameOffset = 0;
                    }
                    self.updateWaveform(self.frameOffset);
                }
            }
        });
        self.peaks.on('user_seek', function (time) {
            var frameIndex = self.timeToPixels(time);
            self.updateWaveform(frameIndex - Math.floor(self.width / 2));
            self._playheadLayer.syncPlayhead(frameIndex);
            if (self._playing) {
                self._playheadLayer.playFrom(time);
            }
        });
        self.peaks.on('user_scroll.zoomview', function (pixelOffset) {
            self.updateWaveform(pixelOffset);
        });
        self.peaks.on('player_play', function (time) {
            self._playing = true;
            self._playheadLayer.playFrom(time);
        });
        self.peaks.on('player_pause', function (time) {
            self._playing = false;
            self._playheadLayer.stop(time);
        });
        self.peaks.on('zoom.update', function (currentScale, previousScale) {
            self.setZoomLevel(currentScale, previousScale);
        });
        peaks.on('window_resize', function () {
            self.container.hidden = true;
        });
        self.peaks.on('window_resize_complete', function (width) {
            self.width = width;
            self.stage.setWidth(self.width);
            self.updateWaveform(self.frameOffset);
            self.container.removeAttribute('hidden');
        });
        function nudgeFrame(direction, large) {
            var increment;
            if (large) {
                increment = direction * self.width;
            } else {
                increment = direction * self.timeToPixels(self.options.nudgeIncrement);
            }
            self.updateWaveform(self.frameOffset + increment);
        }
        self.peaks.on('keyboard.left', nudgeFrame.bind(self, -1, false));
        self.peaks.on('keyboard.right', nudgeFrame.bind(self, 1, false));
        self.peaks.on('keyboard.shift_left', nudgeFrame.bind(self, -1, true));
        self.peaks.on('keyboard.shift_right', nudgeFrame.bind(self, 1, true));
        self.peaks.emit('waveform_ready.zoomview', this);
    }
    WaveformZoomView.prototype.setZoomLevel = function (currentScale, previousScale) {
        if (currentScale === this._scale) {
            return;
        }
        var currentTime = this.peaks.player.getCurrentTime();
        var apexTime;
        var playheadOffsetPixels = this._playheadLayer.getPlayheadOffset();
        if (playheadOffsetPixels >= 0 && playheadOffsetPixels < this.width) {
            apexTime = currentTime;
        } else {
            playheadOffsetPixels = this.width / 2;
            apexTime = this.pixelsToTime(this.frameOffset + playheadOffsetPixels);
        }
        this.resampleData(currentScale);
        var apexPixel = this.timeToPixels(apexTime);
        this.frameOffset = apexPixel - playheadOffsetPixels;
        this.updateWaveform(this.frameOffset);
        this._playheadLayer.zoomLevelChanged();
        if (!this._playing) {
            var playheadPixel = this.timeToPixels(currentTime);
            this._playheadLayer.syncPlayhead(playheadPixel);
        }
    };
    WaveformZoomView.prototype.resampleData = function (scale) {
        this._scale = scale;
        this.data = this.originalWaveformData.resample({ scale: scale });
        this.pixelLength = this.data.adapter.length;
    };
    WaveformZoomView.prototype.timeToPixels = function (time) {
        return Math.floor(time * this.data.adapter.sample_rate / this.data.adapter.scale);
    };
    WaveformZoomView.prototype.pixelsToTime = function (pixels) {
        return pixels * this.data.adapter.scale / this.data.adapter.sample_rate;
    };
    var zoomAdapterMap = {
        'animated': AnimatedZoomAdapter,
        'static': StaticZoomAdapter
    };
    WaveformZoomView.prototype.createZoomAdapter = function (currentScale, previousScale) {
        var ZoomAdapter = zoomAdapterMap[this.peaks.options.zoomAdapter];
        if (!ZoomAdapter) {
            throw new Error('Invalid zoomAdapter: ' + this.peaks.options.zoomAdapter);
        }
        return ZoomAdapter.create(currentScale, previousScale, this);
    };
    WaveformZoomView.prototype.createWaveform = function () {
        var self = this;
        this.waveformShape = new Konva.Shape({
            fill: this.options.zoomWaveformColor,
            strokeWidth: 0,
            sceneFunc: function (context) {
                mixins.drawWaveform(context, self.data, self.frameOffset, self.frameOffset, self.frameOffset + self.width, self.width, self.height);
                context.fillStrokeShape(this);
            }
        });
        this.waveformLayer.add(this.waveformShape);
        this.stage.add(this.waveformLayer);
        this.peaks.emit('zoomview.displaying', 0, this.pixelsToTime(this.width));
    };
    WaveformZoomView.prototype.updateWaveform = function (frameOffset) {
        var upperLimit;
        if (this.pixelLength < this.width) {
            frameOffset = 0;
            upperLimit = this.width;
        } else {
            upperLimit = this.pixelLength - this.width;
        }
        frameOffset = Utils.clamp(frameOffset, 0, upperLimit);
        this.frameOffset = frameOffset;
        var playheadPixel = this._playheadLayer.getPlayheadPixel();
        this._playheadLayer.syncPlayhead(playheadPixel);
        this.waveformLayer.draw();
        var frameStartTime = this.pixelsToTime(this.frameOffset);
        var frameEndTime = this.pixelsToTime(this.frameOffset + this.width);
        this._pointsLayer.updatePoints(frameStartTime, frameEndTime);
        this._segmentsLayer.updateSegments(frameStartTime, frameEndTime);
        this.peaks.emit('zoomview.displaying', frameStartTime, frameEndTime);
    };
    WaveformZoomView.prototype.beginZoom = function () {
        if (this._pointsLayer) {
            this._pointsLayer.setVisible(false);
        }
        if (this._segmentsLayer) {
            this._segmentsLayer.setVisible(false);
        }
    };
    WaveformZoomView.prototype.endZoom = function () {
        if (this._pointsLayer) {
            this._pointsLayer.setVisible(true);
        }
        if (this._segmentsLayer) {
            this._segmentsLayer.setVisible(true);
        }
        var time = this.peaks.player.getCurrentTime();
        this.seekFrame(this.timeToPixels(time));
    };
    WaveformZoomView.prototype.isPlaying = function () {
        return this._playing;
    };
    WaveformZoomView.prototype.destroy = function () {
        if (this.stage) {
            this.stage.destroy();
            this.stage = null;
        }
    };
    return WaveformZoomView;
}(require('peaks/waveform/waveform.axis'), require('peaks/waveform/waveform.mixins'), require('peaks/waveform/waveform.utils'), require('peaks/views/playhead-layer'), require('peaks/views/points-layer'), require('peaks/views/segments-layer'), require('peaks/views/helpers/mousedraghandler'), require('peaks/views/zooms/animated'), require('peaks/views/zooms/static'), require('konva'));