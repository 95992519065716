module.exports = function () {
    'use strict';
    function MouseDragHandler(stage, handlers) {
        this._stage = stage;
        this._handlers = handlers;
        this._dragging = false;
        this._mouseDown = this.mouseDown.bind(this);
        this._mouseUp = this.mouseUp.bind(this);
        this._mouseMove = this.mouseMove.bind(this);
        this._stage.on('mousedown', this._mouseDown);
        this._mouseDownClientX = null;
    }
    MouseDragHandler.prototype.mouseDown = function (event) {
        if (!event.target) {
            return;
        }
        if (event.target.attrs.draggable || event.target.parent.attrs.draggable) {
            return;
        }
        this._mouseDownClientX = event.evt.clientX;
        if (this._handlers.onMouseDown) {
            var mouseDownPosX = this._getMousePosX(this._mouseDownClientX);
            this._handlers.onMouseDown(mouseDownPosX);
        }
        window.addEventListener('mousemove', this._mouseMove, false);
        window.addEventListener('mouseup', this._mouseUp, false);
        window.addEventListener('blur', this._mouseUp, false);
    };
    MouseDragHandler.prototype.mouseMove = function (event) {
        var clientX = event.clientX;
        if (clientX === this._mouseDownClientX) {
            return;
        }
        this._dragging = true;
        if (this._handlers.onMouseMove) {
            var mousePosX = this._getMousePosX(clientX);
            this._handlers.onMouseMove(mousePosX);
        }
    };
    MouseDragHandler.prototype.mouseUp = function (event) {
        if (this._handlers.onMouseUp) {
            var mousePosX = this._getMousePosX(event.clientX);
            this._handlers.onMouseUp(mousePosX);
        }
        window.removeEventListener('mousemove', this._mouseMove, false);
        window.removeEventListener('mouseup', this._mouseUp, false);
        window.removeEventListener('blur', this._mouseUp, false);
        this._dragging = false;
    };
    MouseDragHandler.prototype._getMousePosX = function (clientX) {
        var containerPos = this._stage.getContainer().getBoundingClientRect();
        return clientX - containerPos.left;
    };
    MouseDragHandler.prototype.isDragging = function () {
        return this._dragging;
    };
    return MouseDragHandler;
}();