module.exports = function () {
    'use strict';
    function ZoomController(peaks, zoomLevels) {
        this._peaks = peaks;
        this._zoomLevels = zoomLevels;
        this._zoomLevelIndex = 0;
    }
    ZoomController.prototype.zoomIn = function () {
        this.setZoom(this._zoomLevelIndex - 1);
    };
    ZoomController.prototype.zoomOut = function () {
        this.setZoom(this._zoomLevelIndex + 1);
    };
    ZoomController.prototype.setZoom = function (zoomLevelIndex) {
        if (zoomLevelIndex >= this._zoomLevels.length) {
            zoomLevelIndex = this._zoomLevels.length - 1;
        }
        if (zoomLevelIndex < 0) {
            zoomLevelIndex = 0;
        }
        if (zoomLevelIndex === this._zoomLevelIndex) {
            return;
        }
        var previousZoomLevelIndex = this._zoomLevelIndex;
        this._zoomLevelIndex = zoomLevelIndex;
        this._peaks.emit('zoom.update', this._zoomLevels[zoomLevelIndex], this._zoomLevels[previousZoomLevelIndex]);
    };
    ZoomController.prototype.getZoom = function () {
        return this._zoomLevelIndex;
    };
    ZoomController.prototype.overview = function zoomToOverview() {
        this._peaks.emit('zoom.update', this.peaks.waveform.waveformOverview.data.adapter.scale, this._zoomLevels[this._zoomLevelIndex]);
    };
    ZoomController.prototype.reset = function resetOverview() {
        this._peaks.emit('zoom.update', this._zoomLevels[this._zoomLevelIndex], this._peaks.waveform.waveformOverview.data.adapter.scale);
    };
    return ZoomController;
}();