module.exports = function (Utils) {
    'use strict';
    return {
        create: function (currentScale, previousScale, view) {
            return {
                start: function (relativePosition) {
                    view.segmentLayer.draw();
                    view.pointLayer.draw();
                    var time = view.peaks.player.getCurrentTime();
                    var index = view.timeToPixels(time);
                    view.seekFrame(index, relativePosition);
                }
            };
        }
    };
}(require('peaks/waveform/waveform.utils'));