"use strict";

/**
 * Object adapter consumes stringified JSON or JSON waveform data (data format version 1).
 * It is used as a data abstraction layer by `WaveformData`.
 *
 * This is supposed to be a fallback for browsers not supporting ArrayBuffer:
 * * **Pros**: easy to debug response_data and quite self describing.
 * * **Cons**: slower than ArrayBuffer, more memory consumption.
 *
 * Also, it is recommended to use the `fromResponseData` factory.
 *
 * @see WaveformDataObjectAdapter.fromResponseData
 * @param {String|Object} response_data JSON or stringified JSON
 * @constructor
 */
var WaveformDataObjectAdapter = module.exports = function WaveformDataObjectAdapter(response_data){
  this.data = response_data;
};

/**
 * Detects if a set of data is suitable for the Object adapter.
 * It is used internally by `WaveformData.create` so you should not bother using it.
 *
 * @static
 * @param {Mixed} data
 * @returns {boolean}
 */
WaveformDataObjectAdapter.isCompatible = function isCompatible(data){
  return data && (typeof data === "object" && "sample_rate" in data) || (typeof data === "string" && "sample_rate" in JSON.parse(data));
};

/**
 * Setup factory to create an adapter based on heterogeneous input formats.
 *
 * It is the preferred way to build an adapter instance.
 *
 * ```javascript
 * var objectAdapter = WaveformData.adapters.object;
 * var xhr = new XMLHttpRequest();
 *
 * // .dat file generated by audiowaveform program
 * xhr.open("GET", "http://example.com/waveforms/track.json");
 * xhr.responseType = "json";
 * xhr.addEventListener("load", function onResponse(progressEvent){
 *  var responseData = progressEvent.target.response;
 *
 *  // doing stuff with the raw data ...
 *  // you only have access to WaveformDataObjectAdapter API
 *  var adapter = objectAdapter.fromResponseData(responseData);
 *
 *  // or making things easy by using WaveformData ...
 *  // you have access WaveformData API
 *  var waveform = new WaveformData(responseData, objectAdapter);
 * });
 *
 * xhr.send();
 * ```

 * @static
 * @param {String|Object} response_data JSON or stringified JSON
 * @return {WaveformDataObjectAdapter}
 */
WaveformDataObjectAdapter.fromResponseData = function fromJSONResponseData(response_data){
  if (typeof response_data === "string"){
    return new WaveformDataObjectAdapter(JSON.parse(response_data));
  }
  else{
    return new WaveformDataObjectAdapter(response_data);
  }
};
/**
 * @namespace WaveformDataObjectAdapter
 */
WaveformDataObjectAdapter.prototype = {
  /**
   * Returns the data format version number.
   *
   * @return {Integer} Version number of the consumed data format.
   */
  get version(){
    return this.data.version || 1;
  },
  /**
   * Indicates if the response body is encoded in 8bits.
   *
   * **Notice**: currently the adapter only deals with 8bits encoded data.
   * You should favor that too because of the smaller data network fingerprint.
   *
   * @return {boolean} True if data are declared to be 8bits encoded.
   */
  get is_8_bit(){
    return this.data.bits === 8;
  },
  /**
   * Indicates if the response body is encoded in 16bits.
   *
   * @return {boolean} True if data are declared to be 16bits encoded.
   */
  get is_16_bit(){
    return !this.is_8_bit;
  },
  /**
   * Returns the number of samples per second.
   *
   * @return {Integer} Number of samples per second.
   */
  get sample_rate(){
    return this.data.sample_rate;
  },
  /**
   * Returns the scale (number of samples per pixel).
   *
   * @return {Integer} Number of samples per pixel.
   */
  get scale(){
    return this.data.samples_per_pixel;
  },
  /**
   * Returns the length of the waveform data (number of data points).
   *
   * @return {Integer} Length of the waveform data.
   */
  get length(){
    return this.data.length;
  },
  /**
   * Returns a value at a specific offset.
   *
   * @param {Integer} index
   * @return {number} waveform value
   */
  at: function at_sample(index){
    return Math.round(this.data.data[index]);
  }
};
